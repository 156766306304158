$(document).ready(function(){
    if ($("#topics-link").length) {
        $("#topics-link").click(function() {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#guide-topics").offset().top
            }, 800);
        });
    }

    // highlight active section and fade in content
    if ($('.guidepost').length) {
        let sections = document.querySelectorAll('.guidepost');
        let ioHighlightConfig = {
            rootMargin: '-55% 0px -35%'
        };
        let ioFadeConfig = {
            rootMargin: '0px',
            threshold: .25
        };

        let highlightObserver = new IntersectionObserver(function (entries, self) {
            $.each(entries, function(i, entry) {
                if (entry.isIntersecting) {
                    let ref = entry.target.querySelector('.hidden-anchor');
                    let $active = $('.guidepage-nav a.active');
                    let $newActive = $(`.guidepage-nav a[href="#${ref.id}"]`);
                    if ($active) $active.removeClass('active');
                    if ($newActive) $newActive.addClass('active');
                }
            })
        }, ioHighlightConfig);

        let fadeInObserver = new IntersectionObserver(function (entries, self) {
            $.each(entries, function(i, entry) {
                if (entry.isIntersecting) {
                    $(entry.target).addClass('fade-in');
                }
            })
        }, ioFadeConfig);
    
        $.each(sections, function(i, section) {
            highlightObserver.observe(section);

            const mediaQuery = window.matchMedia('(prefers-reduced-motion: no-preference)');
            if (mediaQuery.matches) {
                fadeInObserver.observe(section.querySelector('header'));
                
                let topics = section.querySelectorAll('.topic-links li');
                $.each(topics, function(i, topic) {
                    fadeInObserver.observe(topic);
                })
            }
        });   
    }

    // fade in financial guide
    if ($('.single-guidepost').length) {
        let sections = document.querySelectorAll('.single-guidepost');
        let overview = document.querySelector('.finance-overview');
    
        let ioFadeConfig = {
            rootMargin: '0px 0px 120px 0px',
            threshold: .5
        };
    
        let fadeInObserver = new IntersectionObserver(function (entries, self) {
            $.each(entries, function(i, entry) {
                if (entry.isIntersecting) {
                    $(entry.target).addClass('fade-in');
                }
            })
        }, ioFadeConfig);
        
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: no-preference)');
        if (mediaQuery.matches) {
            fadeInObserver.observe(overview);
        
            $.each(sections, function(i, section) {
                fadeInObserver.observe(section);
            });   
        }
    }
})
