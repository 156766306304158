$(document).ready(function(){
    if($('.tabs').length > 0) {

        $('.tab-button').click(function() {
            let $activeBtn = $(this);
            let $activePanel = $(`#${$activeBtn.attr('aria-controls')}`);

            if(!$activeBtn.hasClass('is-active')) {
                let $lastActiveBtn = $('button.is-active');
                $lastActiveBtn.attr('aria-selected', false);
                $('.tab-content.is-active').attr('aria-hidden', true);
                $('.is-active').removeClass('is-active');


                $activeBtn.addClass('is-active');
                $activeBtn.attr('aria-selected', true);
                $activePanel.addClass('is-active');
                $activePanel.attr('aria-hidden', false);
            }
        });

        // add left/right controls to tabs
        $('.tab-button').keydown(function (e) {
            if (e.keyCode == 37 || e.keyCode == 39) {
                e.preventDefault();
                $(this).siblings()[0].focus();
                $(this).siblings()[0].click();
            }
        })
    }
});