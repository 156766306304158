$(document).ready(function(){

    let $toggleOpen = $('.menu-toggle-open');
    let $toggleClose = $('.menu-toggle-close');
    let $menu = $('.links'); 
    toggleMenuItems();

    $toggleOpen.click(function() {
        $toggleOpen.addClass('expanded');
        $toggleOpen.attr('aria-expanded', true);

        toggleMenuItems(true);

        $menu.addClass('expanded');
    });

    $toggleClose.click(function() {
        $toggleClose.removeClass('expanded');
        $toggleClose.attr('aria-expanded', false);

        toggleMenuItems(false);

        $menu.removeClass('expanded');
    });

    function toggleMenuItems (expanded) {
        if (expanded) {
            $('.mobile-menu + .links').attr('aria-hidden', 'false');
            $('.mobile-menu + .links button').attr('tabindex', 0);
            $('.mobile-menu + .links a').attr('tabindex', 0);
        } else {
            $('.mobile-menu + .links').attr('aria-hidden', 'true');
            $('.mobile-menu + .links button').attr('tabindex', -1);
            $('.mobile-menu + .links a').attr('tabindex', -1);
        }
    }


    // peek-a-boo menu
    var didScroll = false;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('header').outerHeight();

    $(window).scroll(function (event) {
        didScroll = true;
    });
    
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(this).scrollTop();

        if (Math.abs(lastScrollTop - st) <= delta) return;
        
        if (st > lastScrollTop && st > navbarHeight){
            $('header#main-nav').addClass('nav-up');
            if ($('.filters').length > 0) {
                $('.filters').removeClass('lowered');
            }
        } else {
            if (st + $(window).height() < $(document).height()) {
                if ($('.filters').length > 0) {
                    $('.filters').addClass('lowered');
                }
                $('header#main-nav').removeClass('nav-up');
            }
        }
        
        lastScrollTop = st;
    }


    // dropdown navigation
    $('#dropdown-menu').change(function () {
        window.location.href = window.location.origin + this.value;
    })

}); 