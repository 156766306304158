import LazyLoad from "vanilla-lazyload";


const lazyLoadOptions = {
    elements_selector: ".lazy",
	to_webp: true
};

const createLazyLoadInstance = () => {
	return new LazyLoad(lazyLoadOptions);
};

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);
