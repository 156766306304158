import Cookies from 'js-cookie';

$(function() {
    
    // TODO: cookie notice
    // -- hide cookie info notice
    // $('#btn-cookie-dismiss').on('click', function(e) {
    //     e.preventDefault();
    //     $('#cookie-notice').addClass('d-none');

    //     // -- set cookie
    //     return Cookies.set('privacy_notice_dismissed', 'true', { expires: 100 });
    // });

    // // -- show cookie info notice for new visitors
    // if (Cookies.get('privacy_notice_dismissed') !== 'true') {
    //     $('#cookie-notice').removeClass('d-none');
    // } else {
    //     // increase dismissal by 100 days if user has previously agreed
    //     Cookies.set('privacy_notice_dismissed', 'true', { expires: 100 });
    // }

    // global notice
    const globalNoticePrefix = 'global_notice_dismissed_'
    const globalNoticeSlug = $("#btn-global-notice-dismiss").data('notice-slug');
    const cookieName = globalNoticePrefix + globalNoticeSlug;
    $("#btn-global-notice-dismiss").on('click', function(e) {
        e.preventDefault();
        $("#global-notice").addClass('is-hidden');
        Cookies.set(cookieName, 'true', { expires: 100 });
    })

    if(globalNoticeSlug) {
        if(Cookies.get(cookieName) !== 'true') {
            $('#global-notice').removeClass('is-hidden');
        } else {
            $('#global-notice').addClass('is-hidden');
        }
    }
});