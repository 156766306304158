$(document).ready(function(){
    
    // fade in sections
    if ($('.animate-in').length > 0) {
        let sections = document.querySelectorAll('.animate-in');
        let ioConfig = {
            rootMargin: '0px',
            threshold: .25
        };

        let observer = new IntersectionObserver(function (entries, self) {
            $.each(entries, function(i, entry) {
                if (entry.isIntersecting) {
                    ioHandler(entry);
                }
            })
        }, ioConfig);
        
        $.each(sections, function(i, resource) {
            observer.observe(resource);
        });
        
        function ioHandler(entry) {
            let ref = entry.target;
            $(ref).addClass('fade-in');

            let currentOffset = $(ref).offset().left;
            let prevEleOffset = 10000;
            let prevPrevEleOffset = 10000;

            if ($(ref).prev()[0]) {
                prevEleOffset = $(ref).prev().offset().left;

                if ($(ref).prev().prev()[0]) {
                    prevPrevEleOffset = $(ref).prev().prev().offset().left;
                }
            }
            
            if (currentOffset > prevEleOffset) {
                $(ref).addClass('delayed');
                
                if (prevEleOffset > prevPrevEleOffset) {
                    $(ref).addClass('most-delayed');
                }
            }
        }
    }

    // slideshow nav
    if ($('.slide-navigation-button').length > 0) {
        $('.slide-navigation-button').eq(0).addClass('active');

        // get slideshow element
        let $slideshow = $('.slideshow-viewport');
        $('.slide-navigation-button').click(event => {
            let $slideButton = $(event.target);
            $('.slide-navigation-button').removeClass('active');
            $slideButton.addClass('active');
            let selectedSlide = event.target.getAttribute('data-slide');
            let $slide = $(selectedSlide)

            $slideshow.animate({scrollLeft: $slide.position().left}, 350);
        });

        $slideshow.scroll(function () {
            let slideWidth = $slideshow.width();
            let activeSlide = Math.floor($(this).scrollLeft() / slideWidth);
            $('.slide-navigation-button').removeClass('active');
            $('.slide-navigation-button').eq(activeSlide).addClass('active');
        })
    }
});