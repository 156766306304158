$(document).ready(function(){

    let $toggleButtons = $('.title-button');

    $.each($toggleButtons, function (i, ele) {
        let $section =$(`#${$(this).attr('aria-controls')}`);

        $(ele).click(function() {
            $(this).toggleClass('expanded');
            $section.toggleClass('expanded');
        });
    });

    setTimeout(() => {
        $('.question-group button').first().click();
    }, 400);
}); 