$(document).ready(function(){

    if ($('body').hasClass('search-page')) {
        let $toggleButtons = $('.filters button[id$=toggle]');
        $toggleButtons.click(function () {
            let $button = $(this);
            let $dropdown = $button.next();

            if ($button.hasClass('expanded')){
                closeMenu($button, $dropdown)
            } else {
                openMenu($button, $dropdown)
            }
        })

        function openMenu (button, dropdown) {
            if ($('.filters button.expanded')) $('.filters button.expanded').click();

            button.addClass('expanded');
            button.attr('aria-expanded', true);
            dropdown.addClass('expanded')
        }

        function closeMenu (button, dropdown) {
            button.removeClass('expanded');
            button.attr('aria-expanded', false);
            dropdown.removeClass('expanded') 
        }

        let $clearBtn = $('#clear-btn')
        $clearBtn.click(function (event) {
            event.preventDefault();
            window.location = window.location.href.split("?")[0];
        });

        $('body').click(function(e){
            if ($(e.target).parents('.menu-container').length > 0) return;
            if ($('.filters button.expanded')) $('.filters button.expanded').click();
        })
    }
    
    // fade in resources
    if ($('.resources:not(.no-animation)').length > 0) {
        let resources = document.querySelectorAll('.container-card');
        let ioConfig = {
            rootMargin: '0px',
            threshold: .25
        };

        let observer = new IntersectionObserver(function (entries, self) {
            $.each(entries, function(i, entry) {
                if (entry.isIntersecting) {
                    ioHandler(entry);
                }
            })
        }, ioConfig);
        
        $.each(resources, function(i, resource) {
            observer.observe(resource);
        });
        
        function ioHandler(entry) {
            let ref = entry.target;
            $(ref).addClass('fade-in');

            let currentOffset = $(ref).offset().left;
            let prevEleOffset = 10000;
            let prevPrevEleOffset = 10000;

            if ($(ref).prev()[0]) {
                prevEleOffset = $(ref).prev().offset().left;

                if ($(ref).prev().prev()[0]) {
                    prevPrevEleOffset = $(ref).prev().prev().offset().left;
                }
            }
            
            if (currentOffset > prevEleOffset) {
                $(ref).addClass('delayed');
                
                if (prevEleOffset > prevPrevEleOffset) {
                    $(ref).addClass('most-delayed');
                }
            }
        }
    }
}); 