$(document).ready(function() {       
    adjustGuideImages();

    $(window).resize(function() {
        adjustGuideImages();
    });

    function adjustGuideImages () {
        if ($(window).width() >= 769 && $(window).width() <= 1600){
            let mr = parseInt($('.guide .container').css('marginRight'));
            $('.guide .header-image').css('right', `-${mr + 24}px`);
        } 
    }
});